/* You can add global styles to this file, and also import other style files */

// Icons
@import url("https://i.icomoon.io/public/5287269068/ngx-feng/style.css");

// Fonts
// Cruzeiro Fonts
@font-face {
  font-family: Heebo-Regular;
  src: url("https://cruzeiro.s3.amazonaws.com/prod/assets/fonts/Heebo-Regular.ttf") format("truetype");
}
@font-face {
    font-family: Heebo;
    src: url("https://cruzeiro.s3.amazonaws.com/prod/assets/fonts/Heebo-Medium.ttf") format("truetype");
}
@font-face {
    font-family: Heebo-Bold;
    src: url("https://cruzeiro.s3.amazonaws.com/prod/assets/fonts/Heebo-Bold.ttf") format("truetype");
}
@font-face {
    font-family: ApexMk3;
    src: url("https://cruzeiro.s3.amazonaws.com/prod/assets/fonts/Apex+Mk3-Medium.otf") format("truetype");
}
@font-face {
    font-family: ApexMk3-ExtraLight;
    src: url("https://cruzeiro.s3.amazonaws.com/prod/assets/fonts/Apex+Mk3-ExtraLight.otf") format("truetype");
}
@font-face {
  font-family: CityDBol;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/CityDBol.ttf") format("truetype");
}
@font-face {
  font-family: GillSans;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/GillSans.otf") format("truetype");
}
@font-face {
  font-family: GillSansMT;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/GillSans-MT.ttf") format("truetype");
}

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap|Titillium+Web:400,700');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');

//Tektur
@import url('https://fonts.googleapis.com/css2?family=Tektur:wght@400;500;600;700;800;900&display=swap');

//Figtree
@import url('https://fonts.googleapis.com/css2?family=Figtree&:wght@400;500;700;800&display=swap');

//Phudu - Cruzeiro New
@import url('https://fonts.googleapis.com/css2?family=Phudu:wght@400;700&family=Figtree:wght@400;700&display=swap');

//TekturNarrow - Cruzeiro New
@font-face {
  font-family: 'TekturNarrow';
  src: url(https://cruzeiro.s3.amazonaws.com/TekturNarrow-Bold.2ce021746c68e8cb46cd.ttf) format('truetype');
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

// Angular Material Theme
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import '@angular/material/prebuilt-themes/indigo-pink.css';

// Libs imports always last
@import '~@fengbrasil/ngx-fengst-ui/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-auth/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-layout/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-home/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-events/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-account/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-exclusive-content/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-contact/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-partner/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-experience/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-checkout/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-xray/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-security/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-landing/assets/dist/styles';
